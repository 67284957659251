import React from "react";
import SelectShareLocation from "../components/SelectShareLocation";

const SelectLocation = ({ pageContext }) => {
  const { layoutProps, seoData } = pageContext;

  return (
    <>
      <SelectShareLocation restaurant={layoutProps.restaurant} seoData={seoData} />
    </>
  );
};

export default SelectLocation;
